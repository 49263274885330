import { Unstable_Grid2 as Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { debounce } from "lodash";

const BottomLayout = (props) => {
  const [resizeHeight, setresizeHeight] = useState("");
  const handleResize = debounce(() => {
    gridResize();
  }, 500);
  const gridResize = () => {
    let div = document.getElementById("RegBottomLayout");
    if(div === null) return;
    let divTop = div.getBoundingClientRect().top;
    setresizeHeight("calc(100vh - " + (Math.ceil(divTop) + 10) + "px)");
  };
  useEffect(() => {
    gridResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
      <Grid id="RegBottomLayout" container sx={{backgroundColor:"white"} }>
        <Grid xs={12} >
          <Grid
            xs={12}
            container
            justifyContent="center"
            style={{ width: "auto", height: resizeHeight }}
          >
            {props.children}
          </Grid>
        </Grid>
      </Grid>
  );
};
export default BottomLayout;
