import { useState } from "react";
import {
  Divider,
  List,
  ListSubheader,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import { IconChevronDown, IconChevronUp, IconPoint } from "@tabler/icons";
import { useNavigate } from "react-router-dom";

import MenuIcon from "./MenuIcon";
//import { MenuInfo } from "./MenuInfo";
import { useDispatch , useSelector} from "react-redux";
import { setTitle } from "../../../store/TitleSlice";
import { isMobile } from 'react-device-detect'; 
import AxiosCommon from "../../../components/axios/AxiosCommon";

const MenuList = ({menuGroupOpen,handleMenuGroupOpen,handleDrawerOpenMenuList}) => {
  const [selectedMenu, setselectedMenu] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const MenuInfo = useSelector((state) => state.MenuInfo?.data);
  const reduxProfile = useSelector((state) => state.Profile.data);
  const axiosCommon = AxiosCommon();
  const [loading, setLoading] = useState(false);
  // const collapsehandleClick = (id, value) => {
  //   setcollapseOpen({ ...collapseOpen, [id]: !value });
  // };

  const selectedhandleClick = (id, value) => {
    setselectedMenu({ [id]: true });
    dispatch(setTitle({ Title: value + "[" + id.split("/")[1] + "]" }));
    if(isMobile){
      handleDrawerOpenMenuList();
    }
    navigate("./" + id);
    funcLogMenu(id,value);
  };

  const funcLogMenu= async (id, value) => {    
    if (loading) return;
    setLoading(true);

    let params=({ 
      company_cd:reduxProfile?.company_cd,  
      shop_cd:reduxProfile?.shop_cd,  
      user_cd:reduxProfile?.user_cd,  
      login_tp:id.split("/")[0],
      menu_id:id.split("/")[1],
      menu_nm:value
    })
 
    try {
      axiosCommon
        .post("/common/common/insertLog", params)
        .then((response) => {
          if (response?.data?.code === 200) {
          }
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };
  

  //판매등록,판매등록rfid
  // const saleopenhandleClick = (id) => {
  //   if (id === "saleR") {
  //     window.open(
  //       "http://erp.exrkorea.com/install/install_pos_new.jsp?ID=A1001&COMPCD=E",
  //       "_blank"
  //     );
  //   } else if (id === "salerfidR") {
  //     window.open(
  //       "http://erp.exrkorea.com/install/install_pos_rfid_new.jsp?ID=A1001&COMPCD=E",
  //       "_blank"
  //     );
  //   } else if (id === "dashboard") {
  //     navigate("./" + id);
  //     // navigate("./sample/SamplePage8");
  //     navigate("./business/BusniessDashborad");
  //   } else if (id === "dashboardPrivate") {
  //     // navigate("./" + id);
  //     // navigate("./sample/SamplePage9");
  //     navigate("./system/DashboradPrivate");
  //   }
  // };

  //메뉴정보구성
  const memulist = MenuInfo?.map((menugroup) => {
    return (
      /* 메뉴그룹생성 */
      <Box
        key={menugroup.menuid}
        sx={{
          fontSize: 20,
        }}
      >
        <ListItemButton
          onClick={() => {
            // collapsehandleClick(
            //   menugroup.menuid,
            //   collapseOpen[menugroup.menuid]
            // );
            handleMenuGroupOpen(menugroup.menuid);
          }}
          sx={{
            borderRadius: `15px`,
            ml: 2,
            mr: 2,
          }}
        >
          <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
            <MenuIcon menuid={menugroup.menuid} />
          </ListItemIcon>
          <ListItemText primary={menugroup.menunm} />
          {menuGroupOpen[menugroup.menuid] ? (
            <IconChevronUp
              stroke={1.5}
              size="1rem"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            />
          ) : (
            <IconChevronDown
              stroke={1.5}
              size="1rem"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            />
          )}
        </ListItemButton>
        <Collapse
          in={menuGroupOpen[menugroup.menuid]}
          timeout="auto"
          unmountOnExit
        >
          <List
            component="div"
            disablePadding
            sx={{
              position: "relative",
              "&:after": {
                content: "''",
                position: "absolute",
                left: "32px",
                top: 0,
                height: "100%",
                width: "1px",
                opacity: 1,
                //background: theme.palette.primary.light,
              },
            }}
          ></List>
          {/* 메뉴리스트 */}
          {menugroup.detail?.map((menudetail) => {
            return (
              <ListItemButton
                onClick={() => {
                  selectedhandleClick(menudetail.path, menudetail.menunm);
                }}
                selected={selectedMenu[menudetail.menuid] ? true : false}
                key={menudetail.menuid}
                sx={{
                  borderRadius: `15px`,
                  pl: `40px`,
                  ml: 1,
                  mr: 1,
                  pt: `2px`,
                  pb: `2px`
                }}
              >
                <ListItemIcon sx={{ my: "auto", minWidth: 25 }}>
                  <IconPoint stroke={3} size="1rem" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body2" style={{ fontSize: 15 }}>
                      {menudetail.menunm}
                    </Typography>
                  }
                />
              </ListItemButton>
            );
          })}
        </Collapse>
      </Box>
    );
  });

  return (
    <>
      <List sx={{ textAlign: "left" }}>
        {/* <List
          subheader={
            <ListSubheader component="div" id="subheader">
              Home
            </ListSubheader>
          }
        > */}
          {/* <ListItemButton
            sx={{
              borderRadius: `15px`,
              ml: 2,
              mr: 2,
            }}
            onClick={() => {
              saleopenhandleClick("dashboard");
            }}
          >
            <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
              <MenuIcon menuid="dashboard" />
            </ListItemIcon>
            <ListItemText primary="Dash Board Public" />
          </ListItemButton>
          
          <ListItemButton
            sx={{
              borderRadius: `15px`,
              ml: 2,
              mr: 2,
            }}
            onClick={() => {
              saleopenhandleClick("dashboardPrivate");
            }}
          >
            <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
              <MenuIcon menuid="dashboardPrivate" />
            </ListItemIcon>
            <ListItemText primary="Dash Board Private" />
          </ListItemButton>
           */}
          {/* <ListItemButton
            sx={{
              borderRadius: `15px`,
              ml: 2,
              mr: 2,
            }}
            onClick={() => {
              saleopenhandleClick("saleR");
            }}
          >
            <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
              <MenuIcon menuid="saleR" />
            </ListItemIcon>
            <ListItemText primary="판매등록" />
          </ListItemButton> */}


          {/* <ListItemButton
            sx={{
              borderRadius: `15px`,
              ml: 2,
              mr: 2,
            }}
            onClick={() => {
              saleopenhandleClick("salerfidR");
            }}
          >
            <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
              <MenuIcon menuid="salerfidR" />
            </ListItemIcon>
            <ListItemText primary="판매등록(RFID)" />
          </ListItemButton>
           */}
        {/* </List>
         */}
        <Divider sx={{ ml: 2, mr: 2 }} />
        <List
          subheader={
            <ListSubheader component="div" id="subheader">
              Menu
            </ListSubheader>
          }
        >
          {memulist}
        </List>
      </List>
    </>
  );
};

export default MenuList;
