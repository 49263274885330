import * as React from "react";
import { Box, Typography } from "@mui/material";
import ButtonLayout from "../../components/layout/buttonlayout";
import LoadingButtonBase from "../../components/button/LoadingButtonBase";
import FileUploadPopup from "../../components/popUp/FileUploadPopup";

import { useSelector } from "react-redux";

import { useState,   useEffect } from "react";
import TextFieldBase from "../../components/text/TextFieldBase";
import TextFieldBaseMutiline from "../../components/text/TextFieldBaseMutiline";

import { funcMenuWriteYn } from "../../utils/CommonFunction";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AxiosCommon from "../../components/axios/AxiosCommon";
import AxiosLoading from "../../components/axios/AxiosLoading";
import dayjs from "dayjs";


const NoticeSystem01P = (props) => {
  const reduxProfile = useSelector((state) => state.Profile.data);
  const reduxMenuWriteYn = funcMenuWriteYn(useSelector((state) => state.MenuInfo.data), "NoticeSystem01R");
  const axiosCommon = AxiosCommon();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const [validation, setValidation] = useState({});

  const [addValue, setAddValue] = useState({
    company_cd: reduxProfile?.company_cd,
    shop_cd: reduxProfile?.shop_cd,
    user_cd: reduxProfile?.user_cd,
    notice_dt: props.notice_dt ? props.notice_dt : dayjs().format("YYYYMMDD"),
    title_ds: props.title_ds ? props.title_ds : '',
    notice_ds: props.notice_ds ? props.notice_ds : '',
    url_ds: props.url_ds ? props.url_ds : '1',
    notice_cd: props.notice_cd ? props.notice_cd : '',
    notice_tp: props.notice_tp ? props.notice_tp : '',
    password_ds:'1',
    image_cd: "",
    image_path_ds: "",
    files: { name: "" },
    remark_ds: "",
    write_ds: '',
    click_qt:0,
    notice_type_dt:props.notice_type_dt ? props.notice_type_dt : dayjs().format("YYYY-MM-DD"),
  });

  const onChangeAdd = (e) => {
    //일자,텍스트필드,브랜드 오류초기화
    if (
      e.target.name === "notice_ds" ||
      e.target.name === "title_ds" ||
      e.target.name === "password_ds" 
    ) {
      setValidation({ ...validation, [e.target.name]: "" });
    }
    if (e.target.name === "image_path_ds") {
      setAddValue({
        ...addValue,
        [e.target.name]: e.target.files[0].name,
        "files": e.target.files[0],
      });
    }
    else {
      setAddValue({ ...addValue, [e.target.name]: e.target.value });
    }
  };
  
  
  const funcValidation = () => {
    //필수값 체크시 useState값은 비동기로 처리되어 오류를 배열로 만든 후 한번에 넣어야됨
    let validateValue = {};
    //텍스트필드 체크
    if (!addValue["title_ds"]) {
      validateValue = {
        ...validateValue,
        title_ds: "제목을 입력하세요.",
      };
    }
    if (!addValue["notice_ds"]) {
      validateValue = {
        ...validateValue,
        notice_ds: "내용을 입력하세요.",
      };
    }
    if (!addValue["password_ds"]) {
      validateValue = {
        ...validateValue,
        password_ds: "비밀번호를 입력하세요.",
      };
    }


    if (Object.keys(validateValue).length === 0) {
      return true;
    } else {
      setValidation(validateValue);
      return false;
    }
  };

  const funcSearch = async () => {
    //초기화
    setAddValue([]);

    let paramRepo = {
      params: addValue,
    };

    if (loading) return;
    setLoading(true);
    try {
      await axiosCommon
        .get("/community/noticesys01p/select", paramRepo)
        // .get("/community/notice01r/selectMobileInsert", paramRepo)
        .then((response) => {
          if (response.data?.code === 200) {
            response.data.data[0].files = { name: "" };
            // addValue.password_ds ='';            
            setAddValue({
              ...addValue,
              title_ds: response.data.data[0].title_ds,
              notice_ds: response.data.data[0].notice_ds,
              notice_cd: response.data.data[0].notice_cd,
              password_ds:response.data.data[0].password_ds,
              image_path_ds: response.data.data[0].image_path_ds,
              url_ds: response.data.data[0].url_ds,
              image_cd: response.data.data[0].image_cd,
              write_ds: response.data.data[0].user_ds,
              click_qt: response.data.data[0].click_qt,
              notice_type_dt:response.data.data[0].notice_type_dt,
              files: { name: "" },
            });
          }          
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };

  const funcDelete = async () => {
    // if (!funcValidation()) return;
    
    if (addValue["shop_cd"]  !=='SEYEON'  && addValue["user_cd"] !=='admin' ) return;

    if (!window.confirm("삭제 하시겠습니까?")) {
      return;
    }

    if (loading) return;
    setLoading(true);
    try {
      axiosCommon
        .post("/community/noticesys01p/delete", addValue)
        .then((response) => {
          if (response.data?.code === 200) {
            alert("공지사항을 삭제했습니다.");
            // funcSearch();
            // funcNew();
          props.onClick('save');
          }
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };

  const funcSave = async () => {
    if (!funcValidation()) return;

    let url;
    if (addValue["notice_cd"]) {
      url = "/community/noticesys01p/update";
    } else {
      url = "/community/noticesys01p/insert";
    }

    if (loading) return;
    setLoading(true);
    try {
      const response = await axiosCommon.post(url, addValue);
      if (response?.data?.code === 200) {
        //이미지 저장
        const formData = new FormData();
        formData.append("noticesys", addValue.files);
        let jsonData = JSON.stringify({ ...addValue, bill_no: addValue["notice_cd"] ? addValue["notice_cd"] : response.data.data[0]?.notice_cd });
        formData.append("jsonData", jsonData);
        const response2 = await axiosCommon.post("common/file-upload/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        if (response2?.data?.code === 200) {
          alert("저장했습니다.");
          setAddValue({
            ...addValue,
            files: { name: "" },
          });
          // funcSearch();
          props.onClick('save');
        }

      }
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!props.notice_cd) return;
    funcSearch();
    // eslint-disable-next-line
  }, []);


  return (
    <Box>
      {loading && <AxiosLoading />}
      {/* 버튼레이아웃 */}
      <ButtonLayout>
        {reduxMenuWriteYn &&
          <LoadingButtonBase
            autoFocus
            label="등록"
            onClick={funcSave}
            loading={loading}
            variant="contained"
            name="BUTTON_SAVE"
          />}
        {reduxMenuWriteYn &&  
        <LoadingButtonBase
          autoFocus
          label="삭제"
          onClick={funcDelete}
          loading={loading}
          variant="contained"
          name="BUTTON_SAVE"
        />}
        <LoadingButtonBase
          autoFocus
          label="종료"
          onClick={props.onClick}
          loading={loading}
          variant="contained"
          name="BUTTON_CLOSE"
        />
      </ButtonLayout>


      {/* <TopLayout mt='3px'>         */}

      <TableContainer component={Paper} sx={{ width: '99%', minWidth: '500px', ml: 1, mr: 10, mt: 1, mb: 0, }}>
        <Table sx={{ }} aria-label="a dense table" >
          <TableBody>
            <TableRow >
              <TableCell rowSpan={1} sx={{ width: "42px",padding: "0px", pr: "5px", backgroundColor: "#f5f6fa", }}>
                <Typography component="div" variant="caption" sx={{ textAlign: "right", pt: "5px", fontSize:'11px'}}>
                작성자 
                </Typography>  
              </TableCell >
              <TableCell sx={{ p: 0, m:0 }}>    
                <Typography sx={{ ml: 0, textAlign: 'left', height: "36px", pl: "10px" , pt:'10px', fontSize:'12px' }} component="div"  >
                  <font color="black"> {addValue["write_ds"]?addValue["write_ds"]:reduxProfile?.user_ds}</font>                    
                </Typography>  
              </TableCell >  
              <TableCell rowSpan={1} sx={{ width: "100px",padding: "0px", pr: "5px", }}>
                <Typography component="div" variant="caption" sx={{ textAlign: "right", pt: "5px", fontSize:'11px'}}>
                작성일자 
                </Typography>  
              </TableCell >
              <TableCell sx={{ p: 0, m:0 }}>   
                {/* <Typography sx={{ ml: 0, textAlign: 'left', height: "36px", pl: "10px" , pt:'10px', fontSize:'12px' }} component="div"  >
                  <font color="black"> {addValue["notice_type_dt"]}</font>                    
                </Typography>    */}
                <TextFieldBase
                  required={true}
                  readOnly={true}
                  label=""
                  name={"notice_dt"} //바인딩변수
                  onChange={onChangeAdd}
                  value={addValue["notice_dt"]}
                  fontSizeInput={12}                
                /> 
              </TableCell >     
              <TableCell rowSpan={1} sx={{ width: "100px",padding: "0px", pr: "5px", }}>
                <Typography component="div" variant="caption" sx={{ textAlign: "right", pt: "5px", fontSize:'11px'}}>
                조회수 
                </Typography>  
              </TableCell >
              <TableCell sx={{ p: 0, m:0 }}>    
                <TextFieldBase
                  required={true}
                  readOnly={true}
                  label=""
                  name={"click_qt"} //바인딩변수
                  onChange={onChangeAdd}
                  value={addValue["click_qt"]}
                  fontSizeInput={12}            
                /> 
              </TableCell >       
            </TableRow>
            {/* <TableRow >
              <TableCell rowSpan={1} sx={{ width: "42px",padding: "0px", pr: "5px", backgroundColor: "#f5f6fa", borderBottomColor: 'white' }}>                
                <Typography component="div" variant="caption" sx={{ textAlign: "right", pt: "5px", fontSize:'14px'}}>
                비밀번호
                </Typography>  
              </TableCell >
              <TableCell sx={{ p: 0, m:0 }} colSpan={6}>    
                <TextFieldBase
                  required={true}
                  label=""
                  name={"password_ds"} //바인딩변수
                  onChange={onChangeAdd}
                  value={addValue["password_ds"]}
                  width="98%"//
                  fontSizeInput={12}
                  validation={validation["password_ds"]} //값 체크후 오류시 해당 오류메세지 등록                    
                /> 
              </TableCell >       
            </TableRow> */}

            <TableRow hover sx={{ paddingTop: '1px', paddingBottom: '1px' }}   >
              <TableCell sx={{ borderBottomColor: '' }} className='tablecell_base' >
                <Typography sx={{ textAlign: 'right',fontSize:'14px' }} component="div"   >
                  제목
                </Typography>
              </TableCell >
              <TableCell sx={{ p: 0, m:0 }} colSpan={6}>    
                <TextFieldBase
                  required={true}
                  label=""
                  name={"title_ds"} //바인딩변수
                  onChange={onChangeAdd}
                  value={addValue["title_ds"]}
                  width="98%" //컨퍼넌트넓이 default : 222.67 
                  validation={validation["title_ds"]} //값 체크후 오류시 해당 오류메세지 등록                    
                />
              </TableCell>
            </TableRow>
            <TableRow hover sx={{ paddingTop: '1px', paddingBottom: '1px' }}   >
              <TableCell sx={{ borderBottomColor: '' }} className='tablecell_base' >
                <Typography sx={{ textAlign: 'right' ,fontSize:'14px'}} component="div"   >
                  내용
                </Typography>
              </TableCell >
              <TableCell sx={{ p: 0, m:0 }} colSpan={6}>    
                <TextFieldBaseMutiline
                  required={true}
                  readOnly={false}
                  disabled={false}
                  multiline={true}
                  name={"notice_ds"} //바인딩변수  
                  onChange={onChangeAdd} //바인딩 변수 변경 func
                  value={addValue["notice_ds"]} //화면의표시값
                  width="98%" //컨퍼넌트넓이 default : 222.67
                  rows={8}
                  validation={validation["notice_ds"]} //값 체크후 오류시 해당 오류메세지 등록
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} sx={{ width: '99%', ml: 1, mr: 1, mt: 0, mb: 1 }}>
        <Table sx={{ minWidth: 100 }} aria-label="a dense table" >
          <TableBody>
            <TableRow hover sx={{ paddingTop: '1px', paddingBottom: '1px' }}   >
              <TableCell sx={{ borderBottomColor: 'white' }} className='tablecell_base' >
                <Typography sx={{ textAlign: 'right' ,fontSize:'14px'}} component="div"  >
                  파일첨부
                </Typography>
              </TableCell >
              <TableCell sx={{ p: 0, m:0 }} colSpan={6}>    
                <FileUploadPopup
                  //label={"파일업로드"}
                  placeholder={"파일경로"}
                  name="image_path_ds" //바인딩변수
                  value={addValue["image_path_ds"]}
                  onChange={onChangeAdd} //바인딩 변수 변경 func
                  url_ds={addValue["url_ds"]}
                  width="500px" //컨퍼넌트넓이 default : 222.67
                //validation={validation["image_path_ds"]} //값 체크후 오류시 해당 오류메세지 등록
                />
              </TableCell>
              <TableCell sx={{ paddingTop: '1px', paddingBottom: '1px', borderBottomColor: 'white' }}>
                {/* <LoadingButtonBase
                    autoFocus
                    label="등록"
                    onClick={funcAddCode}
                    loading={loading}
                    variant="contained"
                    name="BUTTON_EXCEL"
                    top={3}
                  /> */}
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>


    </Box>
  );
};

export default React.memo(NoticeSystem01P);
