import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  IconCategory,
  IconCheckupList,
  Icon3dCubeSphere,
  IconSocial,
  IconMoodHappy,
 } from '@tabler/icons';

// const MenuListComposition = ({menuGroupOpen,handleMenuGroupOpen,handleDrawerOpenMenuList}) => {
const MenuListComposition =() =>{
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {    
      return;
    }
    if(event.currentTarget.id === undefined) return;
    navigate(event.currentTarget.id);
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <ToggleButton
          ref={anchorRef}
          id="composition-button"
          value={true}
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? true : false}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={{
            border: 'none', // 테두리 제거
            "&:hover": {
              backgroundColor: 'transparent', // hover 시 배경색 제거
            },
          }}
        >
        {open ? <MenuOpenIcon /> : <MenuIcon />}
        </ToggleButton>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          //role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClose} 
                      id="/rentmain/customer/Customer01SMobile"
                      sx={{
                        paddingLeft: '8px', 
                        paddingRight: '10px', 
                      }}
                    ><IconMoodHappy />고객관리</MenuItem>
                    <MenuItem onClick={handleClose} 
                      id="/rentmain/business/BusniessDashboradMobile" 
                      sx={{
                        paddingLeft: '8px', 
                        paddingRight: '10px', 
                      }}><IconCheckupList  /> 업무관리</MenuItem>
                    <MenuItem onClick={handleClose} 
                      id="/rentmain/rent/Rent02RMobile" 
                      sx={{
                        paddingLeft: '8px', 
                        paddingRight: '10px', 
                      }}><Icon3dCubeSphere/>임대관리</MenuItem>
                    <MenuItem onClick={handleClose} 
                      id="/rentmain/sales/Sale01SMobile" 
                      sx={{
                        paddingLeft: '8px', 
                        paddingRight: '10px', 
                      }}><IconCategory/>매출관리</MenuItem>
                    <MenuItem onClick={handleClose} 
                      id="/rentmain/community/Notice01SMobile" 
                      sx={{
                        paddingLeft: '8px', 
                        paddingRight: '10px', 
                      }}><IconSocial/>공지사항</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}

export default MenuListComposition;
