import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
} from "redux-persist";
import { createStateSyncMiddleware, initStateWithPrevTab ,withReduxStateSync    } from 'redux-state-sync';

import SideOpenReducer from "./SideOpenSlice";
import TitleReducr from "./TitleSlice";
import ProfileReducr from "./ProfileSlice";
import LoginReducr from "./LoginSlice";
import CodeInfoReducr from "./CodeInfoSlice";
import MenuInfoReducr from "./MenuInfoSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const config = {
  channel: 'my_broadcast_channel', 
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
};
const middlewares = [createStateSyncMiddleware(config)];

const appReducer  = combineReducers({
  SideOpen: SideOpenReducer,
  Title: TitleReducr,
  Profile: ProfileReducr,
  Login: LoginReducr,
  CodeInfo: CodeInfoReducr,
  MenuInfo: MenuInfoReducr,
});

const persistedReducer = persistReducer(persistConfig, withReduxStateSync(appReducer));

const store = configureStore({
  reducer: persistedReducer,
  middleware:middlewares 
});

// 다른 탭으로 상태 초기화 
initStateWithPrevTab ( store ); 

export const persistor = persistStore(store);
export default store;
