import LoadingButtonSave from "@mui/lab/LoadingButton";
import React,{forwardRef} from "react";
import {useReactToPrint} from "react-to-print";
import {
  IconTag,
  IconCircleX,
  IconSquareOff,
  IconFileSpreadsheet,
  IconSquarePlus,
  IconPencilPlus,
  IconTrash,
  IconPrinter,
  IconDeviceFloppy,
  IconEdit,
  // IconFileSearch,
  IconSend,
  IconReportAnalytics,
  IconMoodSmileBeam,
  IconMoneybag,
  IconLink,
  IconFileCheck,
  IconLayoutGridAdd,
  IconSearch,
  IconFileOrientation,
  IconArrowBack,
  IconPlaylistAdd,
  IconCirclePlus,
  IconCircleMinus,
  IconClock,
  IconCheck,
} from "@tabler/icons";
import CloseIcon from '@mui/icons-material/Close';
const ButtonBase = forwardRef((props,ref) => {
  const onClick = (e) => {
    props.onClick(e, props.name);
    if(ref){
      print();
    }
  }

  const print = useReactToPrint({
    content: () => ref?.current,
  });

  const icons = () => {
    if (props.label === "불가") {
      return <IconCircleX />;
    } else if (props.label === "확정취소") {
      return <IconSquareOff />;
    } else if (props.label === "RFID") {
      return <IconTag />;
    } else if (props.label === "엑셀") {
      return <IconFileSpreadsheet />;
    } else if (props.label === "신규") {
      return <IconSquarePlus />;
    } else if (props.label === "출력") {
      return <IconPrinter />;
    } else if (props.label === "알람보내기") {
      return <IconSend />;
    } else if (props.label === "상품추가") {
      return <IconPencilPlus />;
    } else if (props.label === "권한등록") {
      return <IconPencilPlus />;
    } else if (props.label === "행번삭제") {
      return <IconTrash />;
    } else if (props.label === "임대등록") {
      return <IconReportAnalytics />;
    } else if (props.label === "카운터") {
      return <IconEdit />;
    } else if (props.label === "카운터신규") {
      return <IconEdit />;
    } else if (props.label === "무상지원") {
      return <IconMoodSmileBeam />;
    } else if (props.label === "제품잔존가") {
      return <IconMoneybag />;    
    } else if (props.label === "입력추가") {
      return <IconPencilPlus />;
    } else if (props.label === "입력삭제") {
      return <IconTrash />;
    } else if (props.label === "취소") {
      return <IconArrowBack />;
    } else if (props.label === "삭제") {
      return <IconTrash />;
    } else if (props.label === "등록") {
      return <IconDeviceFloppy />;
    } else if (props.label === "추가") {
      return <IconPlaylistAdd />;
    } else if (props.label === "수정") {
      return <IconEdit />;      
    } else if (props.label === "확인") {
      return <IconFileCheck />;   
    } else if (props.label === "IP") {
      return <IconLink />;    
    } else if (props.label === "조회") {
      return <IconSearch />;   
    } else if (props.label === "종료") {
      return <CloseIcon />;   
    } else if (props.label === "닫기") {
      return <IconFileOrientation />;   
    } else if (props.label === "상세") {
      return <IconLayoutGridAdd />;   
    } else if (props.label === "회계반영") {
      return <IconDeviceFloppy />;
    } else if (props.label === "회계취소") {
      return <IconArrowBack />;
    } else if (props.label === "점검마감") {
      return <IconClock />;
    } else if (props.label === "마감취소") {
      return <IconArrowBack />;
    } else if (props.name === "BUTTON_RENT") { //임대료생성
      return <IconCirclePlus />;  
    } else if (props.name === "BUTTON_RENT_MINUS") { //임대료삭제
      return <IconCircleMinus />;      
    } else if (props.name === "BUTTON_SEARCH") {
      return <IconSearch />;          
    } else if (props.label === "결재") {
      return <IconCheck />;                
    } else if (props.label === "승인") {
      return <IconCheck />;                
    } else {
      if(props.name === "IP"){
        return <IconLink />;
      }else{
        // return <IconTag />;
        return '';
      }
    }
  };
// secondary
  return (
    <LoadingButtonSave
      disabled={props.disabled}
      // color={props.color ? "":"#fff"}
      onClick={onClick}
      // onKeyPress={onKeyPress}
      // onSubmit={props.onSubmit}
      id={props.id}
      label={props.label}
      loading={props.loading}
      loadingPosition="start"
      variant={props.readOnly ? "filled" : "outlined"}
      startIcon={icons()}
      // type="submit"
      name={props.name}
      // justifyContent= "right"
      size="small"
      sx={{
        margin: 0.2,   
        pl:'6px',     
        pr:'6px',    
        // width:props.width?props.width:'100%',
        minWidth:'50px',
        // minWidth: props.minWidth?props.minWidth:70,
        minHeight: props.minHeight?props.minHeight:'30px',
        // height: props.height?props.height:35,
        // height:35,
        // color: "#fff",
        // backgroundColor: "#2C6AD2",
        top:props.top ,
      }}
    >
      {props.label}
    </LoadingButtonSave>
  );
});

//props default value
ButtonBase.defaultProps = {
  disabled: false,
};

export default ButtonBase;
