import { createSlice } from "@reduxjs/toolkit";

const LoginSlice = createSlice({
    name: 'Login',
    initialState: {
      value: false
    },
    reducers: {
      setLogin: state => {
        state.value = true
      },
      setLogout: state => {
        state.value = false
      },
    }
  })
  
// Action creators are generated for each case reducer function
export const { setLogin, setLogout } = LoginSlice.actions

export default LoginSlice.reducer  