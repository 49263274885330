import { createSlice } from "@reduxjs/toolkit";

const CodeInfo = createSlice({
  name: "CodeInfo",
  initialState: {
  },
  reducers: {
    setCodeInfo: (state, {payload : data }) => {
      return {...state, data};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCodeInfo } = CodeInfo.actions;

export default CodeInfo.reducer;
