import React from "react";
import TextField from "@mui/material/TextField";
import { red } from "@mui/material/colors";

const InputTextField = (props) => {

  const handleChange = (e) => {
    props.onChange(e);
  };

  const onKeyPress = (e) => {
    if (e.charCode === 13) {
      if (typeof props.onKeyPressEnter === "function"){
        props.onKeyPressEnter(e);
      }
    }
  };
  return (
    <TextField
      disabled={props.disabled}
      error={!props.validation?false:true}
      required={props.required}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
      name={props.name}
      value={!props.value ? "" : props.value}
      multiline={props.multiline} 
      maxRows={props.maxRows}
      rows={props.rows}
      onChange={(e) => handleChange(e)}
      onKeyUp={onKeyPress}
      autoComplete={props.autoComplete}
      size="small"      
      helperText={props.validation}
      InputProps={{
        readOnly: props.readOnly,  
        sx: {
        },
      }}      
      InputLabelProps={{
        style: { 
          fontSize: '12px', 
          color:props.color? props.color:'#000000',
        }
      }}
      sx={
        props.required
          ? {
              m: 1,
              width: props.width,
              "& .MuiInputLabel-root ": { color: red[600], },
              "& .MuiInputLabel-root .Mui-focused": { color: red[600] },
              /* "& .MuiOutlinedInput-root ": {
                " fieldset": { borderColor: red[600] },
                "&.Mui-focused fieldset": { borderColor: red[600] },
                "&:hover fieldset": { borderColor: red[100] }, 
              },*/
              "& .MuiOutlinedInput-root": {
                height: "100%",
                padding: "4px", // 여기에 원하는 padding 값을 설정
                fontSize: props.fontSizeInput ? props.fontSizeInput : "14.5px",
              },
            }
          : {
              m: 1,
              width: props.width,
              "& .MuiOutlinedInput-root": {
                height: "100%",
                padding: "4px", // 여기에 원하는 padding 값을 설정
                fontSize: props.fontSizeInput ? props.fontSizeInput : "14.5px",
              },
            }
      }
    />
  );
};

export default React.memo(InputTextField);
