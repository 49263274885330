import { 
  Typography,  
  Box ,
  ToggleButton,
} from "@mui/material";
import "../../common/css/dashboard.css";
import { isMobile } from 'react-device-detect';
// import { IconDeviceDesktop} from "@tabler/icons";
import NoticeSystem01R from '../../community/NoticeSystem01R'
import { useState, } from "react";
// import { funcMenuWriteYn } from "../../../utils/CommonFunction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

const HeaderNotice = (props) => {  
  // const reduxMenuWriteYn = funcMenuWriteYn(useSelector((state) => state.MenuInfo.data), "NoticeSystem01R");
  const [noticeSystem01R, setNoticeSystem01R] = useState({
    open:false,
    notice_cd:'',
  });

  const onClick = (e) => {
    setNoticeSystem01R({
      open:true,
      notice_cd:'',
    });
  }
  const funcNoticeSystem01R = (item) => {
    setNoticeSystem01R({
      open: false,
      notice_cd: '',
    })
    // funcSearch();
  }

  return (  
    <Box 
      // style={{ 
      //   display: 'flex', 
      //   justifyContent: 'flex-end',  
      // }}
    >
      <ToggleButton
        color="primary"
        value="check"
        onClick={(e) => onClick(e)}
        sx={{ border: 0 , m:0, p:0}}
      >
        <Typography 
          variant="h8"
          sx={{
            cursor: 'pointer', // 마우스 오버 시 손가락 모양으로 변경
            maxWidth: isMobile?'100px':'300px',
            overflow: 'hidden', // 넘치는 텍스트를 감춤
            textOverflow: 'ellipsis', // 넘치는 텍스트를 '...'으로 표시 "..."
            whiteSpace: 'nowrap', // 줄 바꿈을 방지
            color: 'black', // 텍스트 색상을 파랑으로 변경          
          }}
        >         
          시스템공지사항
        </Typography>
      </ToggleButton>
    
    <Dialog
      // fullScreen
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "95%",
            height:"95%",
            // maxHeight:"90%",
            maxWidth: "1000px",  // Set your width here
            // maxHeight:"1000px",
            // paddingLeft:'10px',
            // paddingRight:'10px',
            overflowY:"auto",
          },
        }
      }}
      open={noticeSystem01R["open"]}
      >
      <DialogTitle />
      <NoticeSystem01R
        gridtheme={props.gridtheme} 
        onClick={funcNoticeSystem01R}  
        notice_cd={noticeSystem01R["notice_cd"]}  
      />
    </Dialog>   

  </Box>
  );
};


export default HeaderNotice;
