import { 
  Typography,  
  Box ,
  // ToggleButton,
} from "@mui/material";
import "../../common/css/dashboard.css";
import { isMobile } from 'react-device-detect';
// import { IconDeviceDesktop} from "@tabler/icons";
import { useSelector } from "react-redux";

const HeaderDayDifference = (props) => {  
  const reduxCodeInfo = useSelector((state) => state.CodeInfo?.data);
  // const dealStartDt = reduxCodeInfo.filter(params => params.codetype_cd === 'SHOP')[0].dealstart_dt;
  const dealend_dt = reduxCodeInfo.filter(params => params.codetype_cd === 'SHOP')[0].dealend_dt;
  const dayDifference = reduxCodeInfo.filter(params => params.codetype_cd === 'SHOP')[0].days_difference;

  if (dayDifference<=60){
    
  }

  return (
    <Box 
      // style={{ 
      //   display: 'flex', 
      //   justifyContent: 'flex-end',  
      // }}
    >
      {/* <ToggleButton
        color="primary"
        value="check"
        // onClick={() => window.open('http://367.co.kr', '_blank')}
        sx={{ border: 0 ,}}
      > */}
      {/* <IconDeviceDesktop style={{ color: "#7478e8" }} /> */}
      <Typography 
        variant="body2"
        sx={{
          // cursor: 'pointer', // 마우스 오버 시 손가락 모양으로 변경
          maxWidth: isMobile?'100px':'300px',
          overflow: 'hidden', // 넘치는 텍스트를 감춤
          textOverflow: 'ellipsis', // 넘치는 텍스트를 '...'으로 표시 "..."
          whiteSpace: 'nowrap', // 줄 바꿈을 방지
          color: 'black', // 텍스트 색상을 파랑으로 변경          
        }}
      >         
        {/* 사용만료일&nbsp;:&nbsp;{dealend_dt}&nbsp;          */}
        <Box 
          component="span" 
          sx={{
            color: dayDifference <= 60 ? 'red' : 'black', // dayDifference가 60일보다 작으면 빨강, 그렇지 않으면 검정
          }}
        >
          사용만료일&nbsp;:&nbsp;{dealend_dt}&nbsp;[{dayDifference}일]
        </Box>
      </Typography>

      
    {/* </ToggleButton> */}
  </Box>
  );
};


export default HeaderDayDifference;
