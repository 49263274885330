import React,{useEffect,useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

const Notfoundpage = () => {
  const navigate = useNavigate();
  const [home,setHome] = useState(false);

  useEffect( ()=>{
    if(home) navigate(-1);
  },[home,navigate])

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      
      <Box
        sx={{
          p: 7,
          border: 2,
          borderColor: "grey.500",
          width: "100%",
          maxWidth: 500,
          borderRadius: "20px", 
          backgroundColor: "white", 
        }}
      >
        <Typography variant="h4" component="div" gutterBottom align="center">
          요청하신 페이지를 찾을 수 없습니다.
        </Typography>
        <br />
        <Typography variant="h4" component="div" gutterBottom align="center">
          <Button variant="contained" size="large" onClick={() => setHome(true)}>
            뒤로가기
          </Button>
        </Typography>
        <br />
      </Box>
    </Grid>
  );
};

export default Notfoundpage;
