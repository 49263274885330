import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  Box,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { IconLogout, IconCircleCheck, IconDeviceFloppy } from "@tabler/icons";
import { regexNumberCheck } from "../../../utils/Common";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../../store/LoginSlice";
import { setProfile } from "../../../store/ProfileSlice";
import AxiosCommon from "../../../components/axios/AxiosCommon";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { isMobile } from 'react-device-detect';

const Profile = () => {
  const axiosCommon = AxiosCommon();
  const [open, setopen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(false);
  const reduxProfile = useSelector((state) => state.Profile?.data);
  const [loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState({
    passWord: false,
    confirm: false,
    changeWord: false,
  });
  const [reset, setreset] = useState({
    company_cd: reduxProfile?.company_cd,
    user_cd: reduxProfile?.user_cd,
    user_ds: reduxProfile?.user_ds,
    hpno1: reduxProfile?.hpno1,
    hpno2: reduxProfile?.hpno2,
    hpno3: reduxProfile?.hpno3,
    shop_cd: reduxProfile?.shop_cd,
    user_password: "",
    confirm: "",
    changeWd: "",
  });
  const reduxDispatch = useDispatch();

  const handleClickShowPassword = (id) => {
    setshowPassword({ ...showPassword, [id]: !showPassword[id] });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setSelected(!selected);
    setopen(!open);
  };
  const handleChange = (e, id) => {
    if (e.target.value === "" || regexNumberCheck(e.target.value)) {
      setreset({ ...reset, [id]: e.target.value });
    }
  };
  const logouthandleClick = () => {
    reduxDispatch(setLogout());
  };
  const onSubmited = (event) => {
    event.preventDefault();
    if (reset["changeWd"] !== reset["confirm"]) {
      alert("변경 비밀번호와 확인 비밀번호가 틀립니다.");
      return;
    }
    if (loading) return;
    setLoading(true);
    doRetrieve(reset);
  };

  /**Profile변경 api호출*/
  const doRetrieve = async (paramRepo) => {
    try {
      await axiosCommon
        .post("/login/authentication/profile", paramRepo)
        .then((response) => {
          if (response.data?.code === 200) {
            reduxDispatch(setProfile(response.data.data));
            alert("저장되었습니다.");
          }
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };

  //저장후 초기화
  useEffect(() => {
    setreset({
      ...reset,
      company_cd: reduxProfile?.company_cd,
      user_cd: reduxProfile?.user_cd,
      user_ds: reduxProfile?.user_ds,
      hpno1: reduxProfile?.hpno1,
      hpno2: reduxProfile?.hpno2,
      hpno3: reduxProfile?.hpno3,
      user_password: "",
      confirm: "",
      changeWd: "",
    });
    // eslint-disable-next-line
  }, [reduxProfile]);

  return (
    <Box
      sx={{
        //display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 80,
      }}
    >
      <Box style={{ display: 'flex', justifyContent: 'flex-end',  }}>
        <ToggleButton
          color="primary"
          value="check"
          selected={selected}
          onClick={(e) => handleClick(e)}
          sx={{ border: 0 ,}}
        >
          <AccountCircle fontSize="medium" sx={{ mr: 1 }} />

          <Typography 
            variant="h8"
            sx={{
              // cursor: 'pointer', // 마우스 오버 시 손가락 모양으로 변경
              maxWidth: isMobile?'100px':'200px',
              overflow: 'hidden', // 넘치는 텍스트를 감춤
              textOverflow: 'ellipsis', // 넘치는 텍스트를 '...'으로 표시 "..."
              whiteSpace: 'nowrap', // 줄 바꿈을 방지
              color: 'black', // 텍스트 색상을 파랑으로 변경       
            }}
          >         
            {reset["user_ds"]}
          </Typography>
        </ToggleButton>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 5],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={3}>
              <Box>
                <List sx={{ textAlign: "left" }}>
                  <List
                    subheader={
                      <ListSubheader component="div" id="subheader">
                        ACCOUNT
                      </ListSubheader>
                    }
                  >
                    <ListItemButton
                      sx={{
                        borderRadius: `15px`,
                        ml: 2,
                        mr: 2,
                      }}
                      onClick={() => {}}
                    >
                      <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                        <AccountCircle fontSize="medium" sx={{ mr: 1 }} />
                      </ListItemIcon>
                      <ListItemText primary={reset["user_cd"]} />
                      <ListItemText primary={reset["user_ds"]} />
                    </ListItemButton>
                    <Divider sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}></Divider>
                  </List>
                  <List
                    subheader={
                      <ListSubheader component="div" id="subheader">
                        PASSWORD
                      </ListSubheader>
                    }
                  >
                    <form onSubmit={onSubmited}>
                      <ListItemButton
                        sx={{
                          borderRadius: `15px`,
                          ml: 2,
                          mr: 2,
                        }}
                        onClick={() => {}}
                      >
                        <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                          <VpnKeyIcon fontSize="medium" sx={{ mr: 1 }} />
                        </ListItemIcon>
                        <ListItemText>
                          <TextField
                            required
                            id="password"
                            label="현재 비밀번호"
                            type={
                              showPassword["passWord"] ? "text" : "password"
                            }
                            fullWidth
                            size="small"
                            sx={{ marginBottom: 1 }}
                            value={reset["user_password"]}
                            onChange={(e) => handleChange(e, "user_password")}
                            autoComplete='off'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) =>
                                      handleClickShowPassword("passWord")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword["passWord"] ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListItemText>
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `15px`,
                          ml: 2,
                          mr: 2,
                        }}
                      >
                        <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                          <ChangeCircleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <TextField
                            required
                            id="changeWd"
                            label="변경 비밀번호"
                            type={
                              showPassword["changeWord"] ? "text" : "password"
                            }
                            fullWidth
                            size="small"
                            sx={{ marginBottom: 1 }}
                            value={reset["changeWd"]}
                            onChange={(e) => handleChange(e, "changeWd")}
                            autoComplete='off'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) =>
                                      handleClickShowPassword("changeWord")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword["changeWord"] ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListItemText>
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `15px`,
                          ml: 2,
                          mr: 2,
                        }}
                      >
                        <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                          <IconCircleCheck />
                        </ListItemIcon>
                        <ListItemText>
                          <TextField
                            required
                            id="confirm"
                            label="확인 비밀번호"
                            type={showPassword["confirm"] ? "text" : "password"}
                            fullWidth
                            size="small"
                            sx={{ marginBottom: 1 }}
                            value={reset["confirm"]}
                            onChange={(e) => handleChange(e, "confirm")}
                            autoComplete='off'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) =>
                                      handleClickShowPassword("confirm")
                                    }
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword["confirm"] ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </ListItemText>
                      </ListItemButton>

                      <ListItemButton
                        sx={{
                          borderRadius: `15px`,
                          ml: 2,
                          mr: 2,
                        }}
                        onClick={() => {}}
                      >
                        <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                          <SmartphoneIcon fontSize="medium" sx={{ mr: 1 }} />
                        </ListItemIcon>
                        <ListItemText>
                          <TextField
                            required
                            id="hp1"
                            label="핸드폰"
                            size="small"
                            inputProps={{ maxLength: 3 }}
                            onChange={(e) => handleChange(e, "hpno1")}
                            sx={{ mr: 1 }}
                            style={{ width: 90 }}
                            value={reset["hpno1"]}
                          />
                          <TextField
                            required
                            id="hp2"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            onChange={(e) => handleChange(e, "hpno2")}
                            sx={{ mr: 1 }}
                            style={{ width: 75 }}
                            value={reset["hpno2"]}
                          />
                          <TextField
                            required
                            id="hp3"
                            size="small"
                            inputProps={{ maxLength: 4 }}
                            onChange={(e) => handleChange(e, "hpno3")}
                            sx={{}}
                            style={{ width: 75 }}
                            value={reset["hpno3"]}
                          />
                        </ListItemText>
                      </ListItemButton>

                      <Box
                        sx={{
                          borderRadius: `15px`,
                          textAlign: "center",
                          ml: 2,
                          mr: 2,
                        }}
                      >
                        <ToggleButton
                          color="primary"
                          value="check"
                          type="submit"
                          disabled={loading}
                          selected={false}
                          sx={{ border: 0 }}
                        >
                          <IconDeviceFloppy fontSize="medium" sx={{ mr: 1 }} />
                          변경하기
                        </ToggleButton>

                        {loading && (
                          <CircularProgress
                            size={50}
                            sx={{
                              color: green[500],
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </Box>
                    </form>
                    <Divider sx={{ ml: 2, mr: 2, mt: 1, mb: 1 }}></Divider>
                    <List>
                      <ListItemButton
                        sx={{
                          borderRadius: `15px`,
                          ml: 2,
                          mr: 2,
                        }}
                        onClick={() => logouthandleClick()}
                      >
                        <ListItemIcon sx={{ my: "auto", minWidth: 36 }}>
                          <IconLogout />
                        </ListItemIcon>
                        <ListItemText primary="로그아웃" />
                      </ListItemButton>
                    </List>
                  </List>
                </List>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
