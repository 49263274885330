import { createSlice } from "@reduxjs/toolkit";

const MenuInfo = createSlice({
  name: "MenuInfo",
  initialState: {
  },
  reducers: {
    setMenuInfo: (state, {payload : data }) => {
      return {...state, data};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMenuInfo } = MenuInfo.actions;

export default MenuInfo.reducer;
