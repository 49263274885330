export const currencyFormatter = (params) => {
  return formatNumber(params.value);
  // return validation(params.value);
};  

const formatNumber = (number) => {
  // this puts commas into the number eg 1000 goes to 1,000,
  // i pulled this from stack overflow, i have no idea how it works
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const dateFormatter =(params) => {  
  var dateAsString = params;
  var dateParts = dateAsString.split('/');
  return `${dateParts[0]} - ${dateParts[1]} - ${dateParts[2]}`;
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export const funcMenuWriteYn = (menuInfo,value) =>{
  let menuDetInfo = [];
  menuInfo.map((item)=>{
    return item?.detail.map((itemDet)=>{
      return menuDetInfo.push(itemDet);
    })
  });
  let returnValue = menuDetInfo.find(({menuid})=>menuid===value)?.write_yn;
  if(returnValue === undefined || returnValue === "undefined" || returnValue === 'N'){
    return false;
  } else{
    return true;
  }
}

// const validation = (e) => {
//   let check = /[~!@#$%^&*()_+|<>?:{}.,/;='"ㄱ-ㅎ | ㅏ-ㅣ |가-힣]/;
//   return check.test(e);
// };
  