import { createSlice } from "@reduxjs/toolkit";

const SideOpenSlice = createSlice({
    name: 'SideOpen',
    initialState: {
      value: true
    },
    reducers: {
      SideOpen: state => {
        state.value = true
      },
      SideClose: state => {
        state.value = false
      },
    }
  })
  
// Action creators are generated for each case reducer function
export const { SideOpen, SideClose } = SideOpenSlice.actions

export default SideOpenSlice.reducer  