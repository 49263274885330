import { Drawer, Box } from "@mui/material";
import { drawerWidth } from "../constant";
import Logo from "../../../assets/logo.png";
import MenuList from "./MenuList";
import { useNavigate, } from "react-router-dom";
import HeaderDayDifference from "../../common/header/HeaderDayDifference";


const Sidebar = ({ drawerOpen, menuGroupOpen, handleMenuGroupOpen, handleDrawerOpenMenuList }) => {
  
  const navigate = useNavigate();
  const home = () => {
    // if (location.pathname === "/PosMain/business/BusniessDashboradMobile") return;
    // setOpen(false);
    // navigate("/PosMain");
    navigate("/rentmain");
  }
  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <Box
          id="drawerBox"
          sx={{mt:2,}}
        >
          {/* <img src={Logo} alt="Berry" width="220" height="100"     onClick={home}/> */}
          <div style={{ paddingLeft: "20px" }}>
            <img src={Logo} alt="Berry" 
              width="200" height="90" 
              onClick={home}
              style={{ cursor: "pointer" }}
            />            
            <HeaderDayDifference />
          </div>
            <MenuList menuGroupOpen={menuGroupOpen} handleMenuGroupOpen={handleMenuGroupOpen} handleDrawerOpenMenuList={handleDrawerOpenMenuList}></MenuList>
        </Box>

      </Drawer>
    </>
  );
};

export default Sidebar;
