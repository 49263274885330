import "./App.css";
import React from "react";
import Routes from "./pages/routes";
import NavigationScroll from "./pages/common/NavigationScroll";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App" >
      <Helmet htmlAttributes={{ "lang": "ko", "translate": "no" }} >
        <meta name="google" content="notranslate" />
      </Helmet>
      <BrowserRouter>
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </BrowserRouter>
    </div>
  );
}

export default App;
