import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../store/LoginSlice";

const AxiosCommon = () => {
  const reduxDispatch = useDispatch();
  const reduxProfile = useSelector((state) => state.Profile.data);
  //console.log(process.env.REACT_APP_API_HOST);
  const instance = Axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 60000,
    withCredentials: false,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
  });

  let accessToken = JSON.parse(localStorage.getItem("token"))?.accessToken;
  let isTokenRefreshing = false;

  instance.interceptors.request.use(
    (request) => {
      if (
        request.url === "/login/authentication/login" ||
        request.url === "/login/authentication/password/update"
      ) {
        request.headers["Authorization"] = "";
      } else {
        request.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      request.params = {...request.params, g_user_cd: reduxProfile?.user_cd, g_shop_cd: reduxProfile?.shop_cd}

      return request;
    },
    (requestErr) => {
      return Promise.reject(requestErr);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (responseErr) => {
      //response 응답값이 없을경우(네트워크오류)
      if (!responseErr.response) {
        // window.alert(responseErr.message);
        // return Promise.resolve(responseErr);
      }
      const { config } = responseErr;
      const originalRequest = config;
      originalRequest.headers = { ...originalRequest.headers };
      // jwt refresh 처리해야됨
      if (responseErr.response.status === 401) {
        if (!isTokenRefreshing) {
          // isTokenRefreshing이 false인 경우에만 token refresh 요청
          isTokenRefreshing = true;

          await Axios.post(process.env.REACT_APP_API_HOST + "/login/authentication/refresh-token", {
            user_cd: reduxProfile?.user_cd,
            user_ds: reduxProfile?.user_ds,
            refreshToken: JSON.parse(localStorage.getItem("token"))
              ?.refreshToken,
          })
            .then((response) => {
              localStorage.setItem(
                "token",
                JSON.stringify({
                  accessToken: response.data.data.token,
                  refreshToken: response.data.data.refreshToken,
                })
              );
              accessToken = response.data.data.token;
              originalRequest.headers['Authorization'] =`Bearer ${accessToken}`;
            })
            .catch((error) => {
              
              if (error.response.status === 403) {
                reduxDispatch(setLogout());
                console.log(responseErr.response);
                return Promise.resolve(responseErr.response);
              } else {
                return Promise.reject(error);
              }
            });
          isTokenRefreshing = false;
        }

        return instance.request(originalRequest);
      } else if (responseErr.response.status === 403) {
        return Promise.resolve(responseErr.response);
      } else if (responseErr.response.status === 404) {
        window.alert(responseErr.response.statusText);
      } else {
        if (responseErr.response.data.code === "EMPTY") {
          window.alert("조회된 내용이 없습니다.");
        } else {
          window.alert(responseErr.response.data.message);
        }
        return Promise.resolve(responseErr.response);
      }
    }
  );

  return instance;
};

export default AxiosCommon;
