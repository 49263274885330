
import LoadingButtonSend from "@mui/lab/LoadingButton";
import SendIcon from '@mui/icons-material/Send';

const ButtonSearch = (props) => {

  //1 함수
  const onClick = (e) => {          
    props.onClick(e,props.name);   
    // if (e.charCode === 13) {    
    //    props.onClick(e);     
    // }
  }

  return (

      <LoadingButtonSend
        // color="secondary"
        onClick={onClick}                 
        // onKeyPress={onKeyPress}    
        // onSubmit={props.onSubmit}   
        id={props.id}    
        label={props.label}    
        loading={props.loading}
        loadingPosition="start"
        startIcon={<SendIcon/>}
        variant={props.readOnly ? "filled":"outlined"}  
        // type="submit"  
        name={props.name} 
        sx={{
          margin: 0.2,
          maxWidth: 100,
          minWidth: 70,
          // color:"#fff",
          // backgroundColor:"#2C6AD2",      
        }}
        size="small"
      >
        {props.label}
      </LoadingButtonSend>

  );
}

export default ButtonSearch;

