import { createSlice } from "@reduxjs/toolkit";

const Profile = createSlice({
  name: "Profile",
  initialState: {
  },
  reducers: {
    setProfile: (state, {payload : data }) => {
      return {...state, data};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfile } = Profile.actions;

export default Profile.reducer;
