import * as React from "react";
import { Box,  } from "@mui/material";
import ButtonLayout from "../../components/layout/buttonlayout";
import TopLayout from "../../components/layout/searchlayout/TopLayout";
import BottomLayout from "../../components/layout/searchlayout/BottomLayout";
import LoadingButtonBase from "../../components/button/LoadingButtonBase";
import LoadingButtonSend from "../../components/button/LoadingButtonSend";

import NoticeSystem01P from "./NoticeSystem01P";

import { useSelector } from "react-redux";

import { useState,  useRef, useEffect } from "react";
import TextFieldBase from "../../components/text/TextFieldBase";

import { AgGridReact } from "ag-grid-react";
import { colSpan, valueGetter } from "../../components/grid/CommonGrid";
import { funcMenuWriteYn } from "../../utils/CommonFunction";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import AxiosCommon from "../../components/axios/AxiosCommon";
import AxiosLoading from "../../components/axios/AxiosLoading";
// import dayjs from "dayjs";
import { IconFile,} from "@tabler/icons";

// 커스텀 셀 렌더러 컴포넌트 (이미지 또는 아이콘 추가)
const ImageCellRenderer = (params) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {params.data.image_cd === '' || params.data.image_cd === null ? '' : <IconFile style={{ color: "#5383d7" }} />}
      {/*##0716f6" */} 
    </div>    
  );
};

const NoticeSystem01R = (props) => {
  const gridRef = useRef();
  const reduxProfile = useSelector((state) => state.Profile.data);
  const reduxMenuWriteYn = funcMenuWriteYn(useSelector((state) => state.MenuInfo.data), "NoticeSystem01R");
  const axiosCommon = AxiosCommon();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [columnDefs] = useState([
    // {
    //   field: "",
    //   minWidth: 50,
    //   maxWidth: 50,
    //   // headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   editable: false,
    //   hide:true,
    // },
    {
      headerName: "NO",
      field: "seq",
      maxWidth: 50,
      valueGetter: (params) => {
        return valueGetter(params.data.seq, "total", params.node.rowIndex + 1);
      },
      colSpan: (params) => {
        return colSpan(params.data.seq, "total", 3);
      },
    },
    { field: "title_ds", headerName: "제목", maxWidth: 440,
      cellStyle: { justifyContent: "left", textAlign: "center" }  
    },
    { field: "register_dm", headerName: "작성일", maxWidth: 160, 
      cellStyle: { justifyContent: "center", textAlign: "center" } 
    },
    { field: "image_cd", headerName: "파일", minWidth:50,maxWidth: 100, 
      cellStyle: { justifyContent: "center", textAlign: "center" } ,
      cellRenderer:ImageCellRenderer,
    },
    { field: "user_ds", headerName: "작성자", maxWidth: 150, 
      cellStyle: { justifyContent: "center", textAlign: "center" } 
    },
    { field: "click_qt", headerName: "조회수", hide: false,
      maxWidth: 90, cellStyle: { justifyContent: "center", textAlign: "center" } 
    },
    { field: "url_ds", headerName: "", hide: true, },
    { field: "notice_cd", headerName: "", hide: true, },
  ]);
  // eslint-disable-next-line
  const [rowData, setRowData] = useState([]);
  const [whereValue, setWhereValue] = useState({
    company_cd: reduxProfile?.company_cd,
    title_ds: "",
  });

  // const [addValue, ] = useState({
  //   company_cd: reduxProfile?.company_cd,
  //   shop_cd: reduxProfile?.shop_cd,
  //   user_cd: reduxProfile?.user_cd,
  //   notice_dt: props.notice_dt ? props.notice_dt : dayjs().format("YYYYMMDD"),
  //   title_ds: props.title_ds ? props.title_ds : '',
  //   notice_ds: props.notice_ds ? props.notice_ds : '',
  //   url_ds: props.url_ds ? props.url_ds : '1',
  //   notice_cd: props.notice_cd ? props.notice_cd : '',
  //   notice_tp: props.notice_tp ? props.notice_tp : '',
  //   password_ds:'1',
  //   image_cd: "",
  //   image_path_ds: "",
  //   files: { name: "" },
  //   remark_ds: "",
  //   click_qt: "",
  // });

  const onChange = (e) => {
    //일자,텍스트필드,브랜드 오류초기화
    setWhereValue({ ...whereValue, [e.target.name]: e.target.value });
  };

  // const getRowStyle = useCallback((params) => {
  //   if (params.node.rowPinned) {
  //     return {
  //       background: "#F3F3F3",
  //     };
  //   }
  //   return;
  // }, []);

  const funcSearch = async () => {
    //초기화
    setRowData([]);
    console.log('Search initiated with parameters:', whereValue);
    let paramRepo = {
      params: whereValue,
    };

    if (loading) return;
    setLoading(true);
    try {
      await axiosCommon
        // .get("/community/notice01r/select", paramRepo)
        .get("/community/noticesys01r/select", paramRepo)
        .then((response) => {
          console.error('Fetch response:', response);
          if (response.data?.code === 200) {
            // if (response.data.data?.length !==0){
            //   response.data.data[0].files = { name: "" };
            // }
            // addValue.password_ds ='1';
            setRowData(response.data.data);
            console.log('Search sucess response:', response);
          }
        });
    } catch (error) {
      console.error('Fetch error:', error);
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };

  const funcOnCellClicked = async (e) => {
    
    if (e.column.colId ==="image_cd" && e.data.image_cd !== ''){
      window.open(e.data.url_ds, '_blank');
    }else{
      setNoticeSystem01P({      
        open: true,
        notice_cd: e.data.notice_cd ,
      });
    }
    
    let params = { company_cd: e.data.company_cd, notice_cd: e.data.notice_cd };

    if (loading) return;
    setLoading(true);
    try {
      axiosCommon
        .post("/community/noticesys01p/updateCount", params)
        .then((response) => {
          if (response?.data?.code === 200) {
            // alert("저장했습니다.");
          }
        });
    } catch (error) {
      window.alert(error);
    } finally {
      setLoading(false);
    }
  };
  
  //팝업창으로 뜰때 종료 버튼 숨김.
  const [visible, setVisible] = useState({    
    buttonClose:false,
  });  
  const [noticeSystem01P, setNoticeSystem01P] = useState({ 
    open: false,
    notice_cd:'',
  });
  const funcNotice01PClose = (item) => {
    setNoticeSystem01P({
      open: false,
      notice_cd: '',
    })
    // if(item === 'save'){
      funcSearch();
    // }
  }
  const funcNew = async (e) => {
    setNoticeSystem01P({
      open: true,
      notice_cd: '',
    })
  };

  useEffect(() => {
    if(!props.notice_cd && props.notice_cd!=='') {    
      setVisible({...visible,  buttonClose: false,});
    }else{
      setVisible({...visible,  buttonClose: true,});
    };
    funcSearch();
    // eslint-disable-next-line
  }, []);


  return (
    <Box>
      {loading && <AxiosLoading />}
      {/* 버튼레이아웃 */}
      <ButtonLayout>
        <LoadingButtonSend
          label="조회"
          name="BUTTON_SEARCH"
          onClick={funcSearch}
        />
        {reduxMenuWriteYn &&
          <LoadingButtonBase
            label="신규"
            name="BUTTON_SEARCH"
            onClick={funcNew}
          />}
        {/* <LoadingButtonBase
          autoFocus
          label="삭제"
          onClick={funcDelete}
          loading={loading}
          variant="contained"
          name="BUTTON_SAVE"
        /> */}
        {visible["buttonClose"] &&
        <LoadingButtonBase
          autoFocus
          label="종료"
          onClick={props.onClick}
          loading={loading}
          variant="contained"
          name="BUTTON_CLOSE"
        />}        
      </ButtonLayout>

      {/* 조회조건레이아웃 */}
      <TopLayout mb='3px' ml='10px' mr='10px'>
        <TextFieldBase
          required={false}
          // label="제조사/제품명"
          placeholder="제목/내용/작성자"
          name="serch_ds"
          value={whereValue["serch_ds"]}
          onChange={onChange}
          width="350px"
          onKeyPressEnter={funcSearch}
          autoFocus={true}
        />
      </TopLayout>

      <BottomLayout >
        <div
          id="BottomGrid"
          style={{
            height: props.height ? props.height : "98%",
            width: "100%",
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
          className={props.gridtheme}
        >
          <AgGridReact
            defaultColDef={{
              flex: 1,
              minWidth: 30,
              filter: false, //필터
              resizable: true, //컬럼리사이징
              sortable: false, //정렬
              editable: false, //edit
              singleClickEdit: true,
              suppressMovable: true ,
            }}
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            // getRowStyle={getRowStyle}
            rowSelection={"single"}
            // rowMultiSelectWithClick={true}
            // onCellClicked={funcOnCellClicked}
            onCellDoubleClicked={funcOnCellClicked}
            rowHeight={43}
            headerHeight={48}
            pagination={true}
            paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </BottomLayout>

      <Dialog
        // fullScreen
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "90%",
              // height:"100%",
              maxHeight:"90%",
              maxWidth: "1200px",  // Set your width here
              // paddingLeft:'10px',
              // paddingRight:'10px',
              overflowY:"auto",
            },
          }
        }}
        open={noticeSystem01P["open"]}
        >
        <DialogTitle />
        <NoticeSystem01P
          gridtheme={props.gridtheme} 
          onClick={funcNotice01PClose}  
          notice_cd={noticeSystem01P["notice_cd"]}  
        />
      </Dialog>   

    </Box>

  );
};

export default React.memo(NoticeSystem01R);
