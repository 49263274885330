import { createSlice } from "@reduxjs/toolkit";

const Title = createSlice({
  name: "Title",
  initialState: {
    value: "",
  },
  reducers: {
    setTitle: (state, action) => {
      state.value = action.payload.Title;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTitle } = Title.actions;

export default Title.reducer;
