import { Box, Unstable_Grid2 as Grid ,Typography} from "@mui/material";
import { useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';

const ButtonLayout = (Props) => {
  const title = useSelector((state) => state.Title.value);
  return (
    <Box sx={{width:"98%"}}>
      <Grid container spacing={2}>
        <Grid lg={4} md={6} sm={6} xs={12}>
          <Grid xs={12} container justifyContent="flex-start">            
            {!isMobile &&
            <Typography component="div" sx={{pl:'15px', pt:'5px'}} > 
              <li >{!Props.title?title:Props.title}</li>
            </Typography> }
          </Grid>
        </Grid>
        <Grid lg={8} md={6} sm={6} xs={12}>
          <Grid xs={12} container justifyContent = {Props.justifyContent? Props.justifyContent:"flex-end"}> 
            {Props.children}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ButtonLayout;
