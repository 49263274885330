import Profile from "./Profile";

const Header = () => {
  return (
    <>
      <Profile />
    </>
  );
};
export default Header;
